import "./SignIn.css";
import logo from "../../assets/images/hicoinylogo.png";
function DM3Logo() {
  return (
    /* eslint-disable max-len */
    <>
      <img src={logo} width="200px" style={{ alignSelf: "center" }} />
    </>
    /* eslint-enable max-len */
  );
}

export default DM3Logo;
